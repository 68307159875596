<template>
  <div class="nw-nav nw-nav-border">
    <nav class="navbar navbar-expand-md navbar-light justify-content-between">
      <a class="navbar-brand d-none d-md-block" href="#/user/searchsleep"><img class="nw-logo" src="@/assets/nightware-logo.png" alt="NightWare"/></a>
      <a href="#/user/searchsleep" class="navbar-brand d-md-none"><img class="nw-logo" src="@/assets/nightware-logo.png" alt="NightWare"/></a>
      <a href="#" class="navbar-brand"></a>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" :class="$route.path=='/participant/summary' ? 'active' : ''" >
          <a class="nav-link" href="#/participant/summary">Summary</a>
        </li>
        <li class="nav-item" :class="$route.path=='/participant/sessions' ? 'active' : ''" >
          <a class="nav-link" href="#/participant/sessions">Calendar</a>
        </li>
        <li class="nav-item" :class="$route.path=='/participant/session' ? 'active' : ''" >
          <a class="nav-link" href="#/participant/session">Chart</a>
        </li>
        <li class="nav-item" :class="$route.path=='/participant/patient' ? 'active' : ''" >
          <a class="nav-link" href="#/participant/patient">Settings</a>
        </li>
        <!--<li class="nav-item" :class="$route.path=='/participant/surveys' ? 'active' : ''" >
          <a class="nav-link" href="#/participant/surveys">Surveys</a>
        </li>-->
        <li class="nav-item" :class="$route.path=='/participant/logevents' ? 'active' : ''" >
          <a class="nav-link" href="#/participant/logevents">Logs</a>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-cog"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#/user/searchsleep">
              <i class="fa fa-search"></i> Sleep Activity
            </a>
            <a class="dropdown-item" href="#/user/searchpatient">
              <i class="fa fa-search"></i> Search Patients
            </a>
            <a class="dropdown-item" href="#/user/participantbatch">
              <i class="fa fa-list"></i> Patient Batches
            </a>
            <a class="dropdown-item" href="#/user/addparticipant">
              <i class="fa fa-plus"></i> Add Patient
            </a>
            <!-- <a class="dropdown-item" href="#/user/account">
              <i class="fa fa-user"></i> My Account
            </a>-->
            <div class="dropdown-divider"></div>

            <a class="dropdown-item" href="#/participant/sessions">
              <i class="fa fa-calendar"></i> Calendar
            </a>
            <a class="dropdown-item" href="#/participant/session">
              <i class="fa fa-line-chart"></i> Chart
            </a>
            <a class="dropdown-item" href="#/participant/settings">
              <i class="fa fa-wrench"></i> Settings
            </a>
            <a class="dropdown-item" href="#/participant/logs">
              <i class="fa fa-list-alt"></i> Logs
            </a>

            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#/admin/dashboard" v-show="nc.ctx.suRole == true || nc.ctx.adminRole == true">
              <i class="fa fa-cog"></i> Admin
            </a>
            <a href="#" class="dropdown-item text-danger" @click="logout()">
              <i class="fa fa-power-off"></i> Log Out
            </a>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'

export default {
  data () {
      return {
        logo: process.env.VUE_APP_LOGO,
        nc: NetClient
      }
  },
  methods : {
    logout() {
      NetClient.logOut()
    }
  }
}
</script>
<style scoped>
.nw-nav {
  background-color: #FFFFFF;

}
</style>
